footer {
  background-color: white;
  padding: 1rem 0;
  /* box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4); */  
  z-index: 9999;
}

.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 600px) {
  footer {
    margin-bottom: 0;
  }
}

/* @media (min-width: 900px) {
  .footer-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-social > a {
    display: block;
    margin-right: 1rem;
  }
} */
