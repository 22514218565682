.social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-links.center {
  justify-content: center;
}

.social-links.large > a {
  justify-content: center;
  height: 4rem; /* 64px */
  width: 4rem;  /* 64px */
  margin-right: 2rem;
}

.social-links > a {
  display: block;
  margin-right: 1rem;
  height: 1.75rem; /* 28px */
  width: 1.75rem;  /* 28px */
  transition: .3s;
}

.social-links > a:hover {
  transform: scale(1.5);
}
