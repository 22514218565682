html { 
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  /* transition: 1s;   */
}

h1, 
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;  
  /* font-weight: 300; */
}

.section {
  margin: 0;
  padding: 0;
}
.nav {
  width: 100%;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;  
  -webkit-justify-content: center;  
          justify-content: center;  
}

.nav-brand {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: .5rem 0;
  -webkit-align-items: center;
          align-items: center;  
}

.nav-brand header {
  font-size: 16px;
  margin-left: 1rem;
}

.logo-wrapper {
  height: 32px;
  width: 32px;
}

.logo {
  fill: none;
  stroke: #222;
  stroke-width: 29.63px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 1s ease-out forwards;
          animation: dash 1s ease-out forwards;
}

.nav-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;  
}

.nav-link {
  color: black;
  text-decoration: none;
  padding: 1rem;
  /* transition: .3s; */
  font-size: 1rem;
  /* width: 80px; */
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  /* font-weight: 300; */
}

.nav-link-active {
  /* font-weight: 500; */
  background-color: #eff0f4;
}

@media (min-width: 600px) {
  .nav {    
    padding: 0 5%;
    margin: 0 auto;
    max-width: 1600px;
    -webkit-flex-direction: row;
            flex-direction: row;    
    -webkit-justify-content: space-between;    
            justify-content: space-between;
  }

  .nav-links {    
    background-color: unset;
    box-shadow: none;
    -webkit-align-items: center;
            align-items: center;
  }
}

@media (min-width: 900px) {
  .nav-link {
    width: 100px; 
    padding: 1rem;    
  }

  .nav-link:hover {
    font-weight: 500;
    /* background-color: #eff0f4;   */
  }
}
.button {
  text-decoration: none;
  background: #222;
  color: white;
  border: none;
  font: inherit;
  line-height: 1;
  padding: 1rem 2rem;  
  transition: 0.25s;
  cursor: pointer;
  outline: 0;
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.button.ghost {  
  border: 1px solid #222;
  color: #222;
  background: none;
}

.button:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98); 
}

.button.primary {
  background-color: #4379f5;
  color: white;
}
/* .command-bar {
  display: flex;
  justify-content: flex-start;

} */

.command-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.command-bar > * {
  margin-right: .5rem;
}
.container {    
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
}

@media (min-width: 600px) {
  .container {
    padding: 0 5%;
  }
}

@media (min-width: 1200px) {
  .container {
    padding: 0 5%;
  }
}
footer {
  background-color: white;
  padding: 1rem 0;
  /* box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4); */  
  z-index: 9999;
}

.footer-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media (min-width: 600px) {
  footer {
    margin-bottom: 0;
  }
}

/* @media (min-width: 900px) {
  .footer-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-social > a {
    display: block;
    margin-right: 1rem;
  }
} */

ul {  
  list-style: none;
  margin-top: 0;
	padding-left: 0;
}

li {
  padding:1em;
  border-bottom: 1px solid #efefef;
  display: -webkit-flex;
  display: flex;
}

ul.selectable li {
  transition: 0.1s;
  cursor: pointer;
}

ul.selectable li:hover {
  background: rgba(0, 0, 0, 0.05);
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 24px;
}
.lds-ellipsis div {
  position: absolute;
  top: 8px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

.paper {
  margin: 1rem 0;
  background: white;
  padding: 1rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.social-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.social-links.center {
  -webkit-justify-content: center;
          justify-content: center;
}

.social-links.large > a {
  -webkit-justify-content: center;
          justify-content: center;
  height: 4rem; /* 64px */
  width: 4rem;  /* 64px */
  margin-right: 2rem;
}

.social-links > a {
  display: block;
  margin-right: 1rem;
  height: 1.75rem; /* 28px */
  width: 1.75rem;  /* 28px */
  transition: .3s;
}

.social-links > a:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.audio-catalogue {
  margin-top: 2rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  transition: 1s;
}

.audio-catalogue-item img {
  transition: .3s;
  border-radius: 1rem;
}

.audio-catalogue-item:hover img {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.audio-catalogue-item h4,
.audio-catalogue-item h5 {
  font-weight: normal;
}

@media (min-width: 600px) {
  .audio-catalogue {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 900px) {
  .audio-catalogue {
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.audio-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.breadcrumb {
  font-size: 16px;
}

@media (min-width: 600px) {
  .breadcrumb {
    font-size: 20px;
  }
}

@media (min-width: 900px) {
  .audio-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 3fr 2fr;
  }

}

@media (min-width: 1200px) {
  
}
.minecraft-hero {
  height: 70%;
  min-height: 400px;
  background-image: url('https://hentaminecdn.blob.core.windows.net/content/promo-server-night.jpg');
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;;
}

.minecraft-hero h1,
.minecraft-hero p { 
  color: white;
  max-width: 600px;
} 

.minecraft-hero h1 {    
  font-weight: 700;
}

.minecraft-skin-promo {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-gap: 1rem;
}

.minecraft-skin-promo a {
  text-decoration: none;
  color: black;
  transition: .3s;
}

.minecraft-skin-promo a:hover,
.minecraft-skin-promo a:active {  
  color: #aaa;  
}

.minecraft-skin-promo a:hover img,
.minecraft-skin-promo a:active img {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.minecraft-skin-promo img {
  object-fit: cover;
  width: 100%;
  transition: .3s;
  border-radius: 1rem;
}

@media (min-width: 600px) {
  .minecraft-skin-promo {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 900px) {
  .minecraft-skin-promo {    
    grid-template-columns: 1fr 1fr 1fr 1fr;
  } 
}

@media (min-width: 1200px) 
{
  .minecraft-hero {
    height: 70%;
  }
}
.contact-grid {
  display: grid;
  min-height: 80vh;
  -webkit-align-items: center;
          align-items: center;
}

.contact-grid-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;  
}

@media(min-width: 900px) {
  .contact-grid {
    grid-template-columns: 1fr 1fr;    
  }

  .contact-grid-block {    
    -webkit-align-items: center;    
            align-items: center;
    text-align: center;
    padding: 0 2rem;
  }
}
html,
body, 
#root {
  min-height: 100%;
  width: 100%;
}

.section {
  padding: 1rem 0;
}

h1 a,
p a,
.hyperlink {
  text-decoration: none;
  color: #4379f5;
  border-bottom: 2px solid #4379f5;
}

ul {
  margin: 0;
}

dl {
  margin-top: 0;
}

dt {
  font-weight: bold;  
}

dd {
  margin: 0 0 .5rem 0;  
}

.title-1 {
  margin-bottom: .5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.white-bg {
  background-color: white;
}

.gray-bg {
  background-color: #eff0f4;
}

.lavendar {
  background-color: #ba93df;  
}

.error-text {
  color: #c40233;
}

.link-button {
  /* align-self: flex-start;   */
  padding: 1rem;
  font-weight: 500;
  color: #222;  
  text-decoration: none;
  transition: .3s;
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.link-button.wide {
  padding: 1rem 2rem;
}

.link-button:active {
  box-shadow: none;  
}

.link-button.primary {
  background-color: #4379f5;
  color: white;
}

/* Breadcrumb */

.breadcrumb {
  font-weight: 500;
}

/* Content Grid */

.content-grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  width: 100%;
}

.content-grid-block {
  grid-area: content-block;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  text-align: center; 
}

.content-grid-media {
  grid-area: content-media;
  width: 100%;
}

/* .audio-promo .content-grid-media {
  background-color: #222;
} */

@media (min-width: 900px) 
{
  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'content-block content-media';
  }

  .content-grid-block {
    -webkit-justify-content: center;
            justify-content: center;
  }
  

  .content-grid.right {    
    grid-template-areas: 'content-media content-block ';
  }
}

/* Card */

.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: white;
}

.card-image {
  object-fit: cover;
  width: 100%;
}

.card-content {
  padding: 1rem;
}

.card-content p {
  font-size: 14px;
  color: #585e5e;
}

/* Forms & Inputs */

input,
textarea,
select {
  width: 100%;
  box-sizing: border-box;
  border:none;
  border-bottom: 1px solid #777;
  /* border: none; */
  padding: .5em;
  margin-bottom: 1rem;
  font: inherit;
  transition: 0.25s;
  outline: 0;

  
}

input:focus,
textarea:focus,
select:focus {
  border: none;  
  outline-width: 0;
  border-bottom: 2px solid #222;  
}

textarea {
  min-height: 6rem;
  resize: none;
}

@media (min-width: 900px) {
  textarea {
    min-height: 10rem;
  }
}

.field {
  margin-bottom: 1rem;
}

/* Animations */

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

/* Tablet Portrait */
@media (min-width: 600px) 
{
  .section {
    padding: 2rem 0;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) 
{
  html,
  body, 
  #root {
    height: 100%;
    width: 100%;
  }
}

/* Desktop */
@media (min-width: 1200px) 
{
}
