.nav {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;  
  justify-content: center;  
}

.nav-brand {
  display: flex;
  justify-content: center;
  padding: .5rem 0;
  align-items: center;  
}

.nav-brand header {
  font-size: 16px;
  margin-left: 1rem;
}

.logo-wrapper {
  height: 32px;
  width: 32px;
}

.logo {
  fill: none;
  stroke: #222;
  stroke-width: 29.63px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s ease-out forwards;
}

.nav-links {
  display: flex;
  justify-content: center;  
}

.nav-link {
  color: black;
  text-decoration: none;
  padding: 1rem;
  /* transition: .3s; */
  font-size: 1rem;
  /* width: 80px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  /* font-weight: 300; */
}

.nav-link-active {
  /* font-weight: 500; */
  background-color: #eff0f4;
}

@media (min-width: 600px) {
  .nav {    
    padding: 0 5%;
    margin: 0 auto;
    max-width: 1600px;
    flex-direction: row;    
    justify-content: space-between;
  }

  .nav-links {    
    background-color: unset;
    box-shadow: none;
    align-items: center;
  }
}

@media (min-width: 900px) {
  .nav-link {
    width: 100px; 
    padding: 1rem;    
  }

  .nav-link:hover {
    font-weight: 500;
    /* background-color: #eff0f4;   */
  }
}