/* .command-bar {
  display: flex;
  justify-content: flex-start;

} */

.command-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.command-bar > * {
  margin-right: .5rem;
}