.container {    
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
}

@media (min-width: 600px) {
  .container {
    padding: 0 5%;
  }
}

@media (min-width: 1200px) {
  .container {
    padding: 0 5%;
  }
}