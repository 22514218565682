.button {
  text-decoration: none;
  background: #222;
  color: white;
  border: none;
  font: inherit;
  line-height: 1;
  padding: 1rem 2rem;  
  transition: 0.25s;
  cursor: pointer;
  outline: 0;
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.button.ghost {  
  border: 1px solid #222;
  color: #222;
  background: none;
}

.button:active {
    transform: scale(0.98); 
}

.button.primary {
  background-color: #4379f5;
  color: white;
}