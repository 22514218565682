.audio-catalogue {
  margin-top: 2rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  transition: 1s;
}

.audio-catalogue-item img {
  transition: .3s;
  border-radius: 1rem;
}

.audio-catalogue-item:hover img {
  transform: scale(1.025);
}

.audio-catalogue-item h4,
.audio-catalogue-item h5 {
  font-weight: normal;
}

@media (min-width: 600px) {
  .audio-catalogue {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 900px) {
  .audio-catalogue {
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}